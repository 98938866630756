import React from 'react';
import { FaGithub, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

export const socialLinks = [
  {
    name: 'youtube',
    url: 'https://www.youtube.com/',
    icon: <FaYoutube />,
  },
  {
    name: 'instagram',
    url: 'https://www.youtube.com/ag_arunava',
    icon: <FaInstagram />,
  },
  {
    name: 'facebook',
    url: 'https://www.github.com/its_ag',
    icon: <FaGithub />,
  },
  {
    name: 'twitter',
    url: 'https://www.twitter.com/arunava_ag',
    icon: <FaTwitter />,
  },
];
